import { React } from 'react';
import { Grid } from '@material-ui/core';
import { useShopify } from '../hooks';
import Product from './Product';
import Footer from './Footer';
import Cart from './Cart';

const Placeholder = () => {
	const getNumberOfItems = () => {
		const width = window.innerWidth;

		if (width < 960) return 4;
		if (width < 1280) return 6;
		return 8;
	};

	const numberedArray = Array.from(Array(getNumberOfItems()).keys());

	return numberedArray.map((number) => (
		<Grid item xs={6} md={4} lg={3} key={number}>
			<div className="loading_block" />
		</Grid>
	));
};




const Home = (props) => {
	const { products } = useShopify();

	return (
		<>


			<div>
				<div className="floating">
					<img src="././Assets/logo.png" width="90" height="90" alt="TheFrustratedGamer Merch Store" />
				</div>
				<div className="App__header">

					<div className="carousel-image">
						<img src="././Assets/banner.png" alt="TheFrustratedGamer Merch Store" />
					</div>

				</div>

				<div className="App__title">
					<h1 className="App__title-text">The Official FrustratedGamer Clothing Store</h1>
				</div>

			</div>
			<div className="Products-wrapper">
				<Grid container spacing={2}>
					<Grid item xs={false} md={1} xl={2} />
					<Grid item xs={12} md={10} xl={8}>
						<div className="Products-wrapper__inner">
							<Grid container spacing={3}>
								{products && products.length > 0 ? (
									products.map((product, i) => (
										<Grid item xs={6} md={4} lg={3} key={i}>
											<Product product={product} history={props.history} />
										</Grid>
									))
								) : (
									<Placeholder />
								)}
							</Grid>
						</div>
						<Grid item xs={false} md={2} />
					</Grid>
				</Grid>
			</div >

			{/* <div className="App__title">
        <h1 className="App__title-text">Recent Videos</h1>
      </div>

      <div className="youtube-wrapper">
        <Grid container spacing={2}>
          <Grid item xs={false} md={1} xl={2} />
          <Grid item xs={12} md={10} xl={8}>
            <div className="youtube-wrapper__inner">
              <Grid container spacing={3}>
                {products && products.length > 0 ? (
                  products.map((product, i) => (
                    <Grid item xs={6} md={4} lg={3} key={i}>
                      <Product product={product} history={props.history} />
                    </Grid>
                  ))
                ) : (
                  <Placeholder />
                )}
              </Grid>
            </div>
            <Grid item xs={false} md={2} />
          </Grid>
        </Grid>
      </div> */}
			<Cart />
			<Footer />
		</>
	);
};


export default Home;